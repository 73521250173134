import React, { ReactElement } from 'react'

import ModalVideo from '@/components/ui/ModalVideo'
import { ModalVideoOnClick } from '@/components/ui/ModalVideo/types'

import Image from '@/components/ui/Image'
import StyledButton from '@/components/ui/StyledButton'
import Typography from '@/components/ui/Typography'

import s from './styles.module.css'
import { BannerProps } from './types'

/**
 * Displays banner with background image and action button 
 * @param props 
 * @returns 
 */
const Banner = (props: BannerProps) => {
  const { src, heading, actions = [] } = props

  const actionButton = (label: string | ReactElement) => (onClick: ModalVideoOnClick = undefined) => (
    <StyledButton
      color="dark"
      // href={action.href}
      onClick={() => onClick && onClick()}>
      {label}
    </StyledButton>
  )

  return (
    <div className={s.wrapper}>
      <Image
        notched
        priority
        src={src}
        className={s.image}
        sizes="(max-width: 768px) 100vw,
        (max-width: 1200px) 50vw"
        fill />

      <Typography
        className={s.heading}
        as="h2">
        {heading}
      </Typography>

      <>
        {
          actions.map(action => {

            if (action.video === true)
              return <ModalVideo
                key={action.href}
                trigger={actionButton(action.label)}
                src={action.href} />

            return <StyledButton
              key={action.href}
              as='a'
              href={action.href}
              target="_blank"
              variant="filled"
              color="dark">
              {action?.label}
            </StyledButton>
          })
        }
      </>
    </div >
  )
}

export default Banner
