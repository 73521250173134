import React from 'react'
import { Base, Bnb, Cardano, Ethereum } from '@/components/ui/Icon'
import formatNumber from 'helpers/formatNumber'

import { BlockchainType, CopiBalanceProps } from './types'
import s from './styles.module.css'

const CopiBalance = (props: CopiBalanceProps) => {
  const { balances = [] } = props

  const chainBalances: Record<BlockchainType, number> = {
    Cardano: 0,
    Base: 0,
    BNB: 0,
    Ethereum: 0
  }

  balances.forEach(balance => {
    chainBalances[`${balance.blockchain}`] = balance.balance
  })

  return (
    <ul>
      <li className={s.balance_item}>
        <span className={s.balance_label}>
          <Bnb className="w-28 h-5" />
        </span>
        <span
          title={`${chainBalances.BNB.toLocaleString()} COPI`}
          className={s.balance_value}>
          {formatNumber(chainBalances.BNB)}
        </span>
      </li>
      <li className={s.balance_item}>
        <span
          className={s.balance_label}>
          <Cardano className="w-24 h-5" />
        </span>
        <span
          title={`${chainBalances.Cardano.toLocaleString()} COPI`}
          className={s.balance_value}>
          {formatNumber(chainBalances.Cardano)}
        </span>
      </li>
      <li className={s.balance_item}>
        <span className={s.balance_label}>
          <Ethereum className="w-24 h-5" />
        </span>
        <span
          title={`${chainBalances.Ethereum.toLocaleString()} COPI`}
          className={s.balance_value}>
          {formatNumber(chainBalances.Ethereum)}
        </span>
      </li>
      <li className={s.balance_item}>
        <span className={s.balance_label}>
          <Base className="w-16 h-4" />
        </span>
        <span
          title={`${chainBalances.Base.toLocaleString()} COPI`}
          className={s.balance_value}>
          {formatNumber(chainBalances.Base)}
        </span>
      </li>
    </ul>
  )
}

export default CopiBalance